









import Component from 'vue-class-component'
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import {Specification} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";

@Component({})
export default class Specs extends Vue {
  @Prop() items!: Array<Specification>;
}

