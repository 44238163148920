var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "specs"
  }, _vm._l(_vm.items, function (item) {
    return _c('div', {
      key: item.label,
      staticClass: "specs__single"
    }, [_c('p', {
      staticClass: "specs__label"
    }, [_vm._v(_vm._s(item.label))]), _c('p', {
      staticClass: "specs__value",
      domProps: {
        "innerHTML": _vm._s(item.value)
      }
    })]);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }